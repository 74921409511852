import React, { useState, useEffect } from "react";
import SwapForm from "./components/SwapForm";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MintPage from "./components/MintPage";

import { BrowserProvider, formatUnits } from "ethers";

let provider;

if (window.ethereum && window.ethereum.isMetaMask) {
  provider = new BrowserProvider(window.ethereum);
} else {
  console.error("MetaMask is not installed!");
}

const fetchNetworkDataFromAPI = async (chainId) => {
  try {
    const response = await fetch("https://chainid.network/chains.json");
    const chains = await response.json();

    let chainIdInt;
    if (chainId <= Number.MAX_SAFE_INTEGER) {
      chainIdInt = Number(chainId);
    } else {
      throw new Error("chainId is too large to convert safely to a Number.");
    }

    const chainData = chains.find((chain) => chain.chainId === chainIdInt);

    if (chainData) {
      return {
        name: chainData.name,
        symbol: chainData.nativeCurrency?.symbol || "ETH",
        decimals: chainData.nativeCurrency?.decimals || 18,
      };
    } else {
      const availableChainIds = chains.map((chain) => chain.chainId);
      console.error(
        `Network not found for Chain ID: ${chainId}. Available Chain IDs: ${availableChainIds}`
      );
      return {
        name: `Network not found in Chainlist for Chain ID: ${chainId}`,
        symbol: "ETH",
        decimals: 18,
      };
    }
  } catch (error) {
    console.error("Error fetching chain data:", error);
    return {
      name: `Error fetching network for Chain ID: ${chainId}`,
      symbol: "ETH",
      decimals: 18,
    };
  }
};

function App() {
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [balance, setBalance] = useState(null);
  const [network, setNetwork] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("ETH");
  const [decimals, setDecimals] = useState(18);

  const connectWalletHandler = async () => {
    try {
      if (window.ethereum && window.ethereum.isMetaMask) {
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setDefaultAccount(address);

        const balanceUnit = await provider.getBalance(address);
        const formattedBalance = formatUnits(balanceUnit, decimals);
        setBalance(formattedBalance);

        const networkData = await provider.getNetwork();
        setChainId(networkData.chainId);

        const networkDataFromAPI = await fetchNetworkDataFromAPI(
          networkData.chainId
        );
        setNetwork(networkDataFromAPI.name);
        setCurrencySymbol(networkDataFromAPI.symbol);
        setDecimals(networkDataFromAPI.decimals);
      } else {
        setErrorMessage("Please install MetaMask!");
      }
    } catch (error) {
      setErrorMessage("Failed to connect wallet: " + error.message);
    }
  };

  const disconnectWalletHandler = () => {
    setDefaultAccount(null);
    setBalance(null);
    setNetwork(null);
    setChainId(null);
    setCurrencySymbol("ETH");
    setDecimals(18);
  };

  return (
    <Router>
      <div className="max-w-7xl mx-auto p-10 pt-20">
        <Navbar
          defaultAccount={defaultAccount}
          balance={balance}
          network={network}
          chainId={chainId}
          currencySymbol={currencySymbol}
          connectWalletHandler={connectWalletHandler}
          disconnectWalletHandler={disconnectWalletHandler}
        />

        <Routes>
          {/* Main Page with SwapForm */}
          <Route
            path="/"
            element={
              <header className="bg-white p-10 rounded-lg shadow-xl mt-5">
                <SwapForm
                  defaultAccount={defaultAccount}
                  onAccountChange={setDefaultAccount}
                  network={network}
                  chainId={chainId}
                />
              </header>
            }
          />

          {/* New About Page Route */}
          <Route
            path="/mint"
            element={
              <MintPage
                defaultAccount={defaultAccount}
                network={network}
                chainId={chainId}
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
