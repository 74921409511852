export const chains = [
  { id: "997", name: "5ireChain Thunder Testnet" },
  { id: "11155111", name: "Sepolia" },
  { id: "9007", name: "Shido Testnet" },
  // { id: "601", name: "Vine Testnet" },
  // { id: "1404", name: "Vine Mainnet" },
  // { id: "995", name: "5ireChain Mainnet" },
  { id: "17000", name: "Holesky" },
  // { id: "97", name: "BNB Smart Chain Testnet" },
  // { id: "129", name: "Saita Testnet" },
];

// Tokens mapped to each chain including native currencies
export const tokens = {
  9007: [
    { symbol: "SHIDO", address: null }, // Native token for Saita chain
    { symbol: "WETH", address: "0xDE2de0284Deace60f1C84e60a31DFcb9BC61f185" },
    { symbol: "W5IRE", address: "0x11B896DD27F8CFD0dFbe7eDA979194c2779B647F" },
  ],
  997: [
    { symbol: "5IRE", address: null }, // Native token for 5ire chain
    { symbol: "WETH", address: "0xeB16E842478F5693d433648fB85Ab004C1254737" },
    { symbol: "WSHIDO", address: "0x23BA5C4F55Ad2F56D3F8817B62917c03D43650Ba" },

    // { symbol: "USDC", address: "0x..." },
    // { symbol: "WSTC", address: "0xe557247847AD3120D1D8972D77fc09984C3D635b" },
  ],
  11155111: [
    { symbol: "ETH", address: null }, // Native token for Vine chain
    // { symbol: "W5IRE", address: "" },
    // { symbol: "WSHIDO", address: "" },
  ],
  17000: [
    { symbol: "ETH", address: null }, // Native token for Holesky
    // { symbol: "USDC", address: "0x..." },
    { symbol: "W5IRE", address: "0x570F316063d047B45a0B89a6eA154FE879d889eb" },
  ],
  601: [
    { symbol: "VNE", address: null }, // Native token for Vine chain
    { symbol: "W5IRE", address: "0x431Bd997875008F75AD6510879d56c792D7FD83e" },
    { symbol: "WSTC", address: "0xDD80848D46066BB108DBe1C1097b4362219136cF" },
  ],

  // "129": [
  //   { symbol: "SAITA", address: null },  // Native token for Saita chain
  //   { symbol: "W5IRE", address: "0x03FcEd1D0066E4A178Ecd66D2D645c1D8493799D" }
  // ],

  // Add more chains and their respective tokens/native currencies
};

// src/constants.js
export const CONFIG = {
  API_KEY: "your_api_key_here",
  BASE_URL: "https://api.abstraction.antiers.work",
};
