import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import {
  getProvider,
  getSigner,
  sendTransaction,
  handleTokenTransfer,
} from "../utils/signingUtils.js";
import {
  checkServerAvailability,
  notifyMPC,
  generateAddress,
  makeSourseChainTransfer,
  targetChainApiCall,
} from "../utils/mpcUtils.js";
import { tokens, chains } from "../utils/constants";

// Form field component
const FormField = ({
  label,
  id,
  value,
  onChange,
  disabled,
  placeholder,
  type = "text",
}) => (
  <div className="flex flex-col gap-2">
    <label htmlFor={id} className="font-semibold text-gray-700">
      {label}:
    </label>
    <input
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      type={type}
      className="p-3 border border-gray-300 rounded-lg text-lg w-full"
    />
  </div>
);

// Dropdown select component for chains and tokens
const SelectField = ({ label, id, options, value, onChange, disabled }) => (
  <div className="flex flex-col gap-2">
    <label htmlFor={id} className="font-semibold text-gray-700">
      {label}:
    </label>
    <select
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className="p-3 border border-gray-300 rounded-lg text-lg w-full"
    >
      <option value="" disabled>
        Select {label}
      </option>
      {options.map((option) => (
        <option
          key={option.id || option.symbol}
          value={option.id || option.symbol}
        >
          {option.name || option.symbol}
        </option>
      ))}
    </select>
  </div>
);

const SwapButton = ({ isProcessing, status, retry }) => (
  <button
    type="submit"
    className={`p-4 text-lg font-semibold text-white rounded-lg transition-all w-full ${
      status === "success"
        ? "bg-green-500 cursor-not-allowed"
        : retry
        ? "bg-yellow-500 hover:bg-yellow-600"
        : "bg-blue-600 hover:bg-blue-800"
    } ${isProcessing ? "bg-gray-400 cursor-not-allowed" : ""}`}
    disabled={isProcessing || status === "success"}
  >
    {isProcessing
      ? "Processing..."
      : status === "success"
      ? "Transfer Successful"
      : retry
      ? "Retry"
      : "Transfer"}
  </button>
);

const StatusMessage = ({
  statusMessage,
  transactionHash,
  mpcResponceHash,
  fromChain,
  toChain,
}) => (
  <div className="mt-5">
    {statusMessage && (
      <p className="font-bold text-gray-800 mt-2">{statusMessage}</p>
    )}
    {transactionHash && mpcResponceHash && (
      <div className="mt-3 p-3 bg-gray-100 rounded-lg">
        <p className="font-medium">{fromChain} Transaction Hash:</p>
        <code className="block break-all">{transactionHash}</code>
      </div>
    )}
    {mpcResponceHash && (
      <div className="mt-3 p-3 bg-gray-100 rounded-lg">
        <p className="font-medium">{toChain} Transaction Hash:</p>
        <code className="block break-all">{mpcResponceHash}</code>
      </div>
    )}
  </div>
);

function SwapForm({ defaultAccount, onAccountChange, network, chainId }) {
  const [fromChain, setFromChain] = useState(network || "Vine Testnet");
  const [fromChainId, setFromChainId] = useState(chainId );
  const [toChainId, setToChainId] = useState("997");
  const [toChain, setToChain] = useState("5ireChain Thunder Testnet");
  const [fromToken, setFromToken] = useState("");
  const [fromTokenSymbol, setFromTokenSymbol] = useState("");
  const [toToken, setToToken] = useState("");
  const [amount, setAmount] = useState("");
  const [fromAddress, setFromAddress] = useState(defaultAccount || "");
  const [toAddress, setToAddress] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [mpcResponceHash, setmpcResponceHash] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [status, setStatus] = useState("");
  const [retry, setRetry] = useState(false);

  useEffect(() => {
    if (network) {
      setFromChain(network);
    }
    if (chainId) {
      setFromChainId(`${chainId}`);
    }
  }, [network, chainId]);

  // Main handleSwap function
  const handleSwap = async (e) => {
    e.preventDefault(); // Prevents the page from reloading on form submission
    console.log("Form submitted. Beginning swap process...");

    // Check if the wallet is connected
    if (!defaultAccount) {
      console.log("Error: No wallet connected. User must connect wallet.");
      alert("Please connect your wallet first!");
      return;
    }

    console.log("Wallet connected. Proceeding with form validation...");

    // Check if all the required fields are filled out
    if (
      !toChainId ||
      !fromTokenSymbol ||
      !toToken ||
      !fromAddress ||
      !toAddress ||
      !amount
    ) {
      console.log("Error: Incomplete form fields.", {
        toChainId,
        fromTokenSymbol,
        toToken,
        fromAddress,
        toAddress,
        amount,
      });
      alert("Please fill out all the fields!");
      return;
    }

    try {
      setIsProcessing(true); // Set processing state to true
      setRetry(false); // Reset retry status
      setStatusMessage("Starting transaction...");
      console.log("Checking server availability...");

      // Check if the MPC server is available
      const serverIsAvailable = await checkServerAvailability();
      if (!serverIsAvailable) {
        console.log("Error: MPC service unavailable.");
        alert("MPC Service is not available. Please try again later.");
        setStatusMessage("MPC Service is not available. Transaction aborted.");
        setRetry(true);
        setIsProcessing(false);
        return;
      }

      console.log(
        "MPC service is available. Proceeding to generate MPC address..."
      );

      // Generate MPC address based on chain ID
      const generatedMPCAddress = await generateAddress(chainId);
      console.log("MPC Address generated:", generatedMPCAddress);

      const provider = getProvider(); // Fetch the blockchain provider
      if (!provider) {
        console.log("Error: No provider found. Transaction cannot proceed.");
        return;
      }

      console.log("Provider found. Retrieving wallet signer...");
      const signer = await getSigner(provider); // Get the signer (user's wallet)

      // Transfer tokens or native currency based on the selected token and chain
      console.log(
        `Initiating token transfer: fromToken = ${fromTokenSymbol}, toChainId = ${toChainId}, amount = ${amount}`
      );

      //this will handle both native as well as erc20 token transfer
      const transactionResponse = await handleTokenTransfer(
        fromChainId,
        fromTokenSymbol, // Symbol of the token to be transferred
        generatedMPCAddress, // Recipient address
        amount, // Amount to transfer
        signer // Wallet signer
      );

      // Log and save the transaction hash
      setTransactionHash(transactionResponse.hash);
      console.log("Transaction successful! Hash:", transactionResponse.hash);

      setStatusMessage("Transaction successful! Waiting for API response...");

      console.log("Notifying MPC service with transaction details...");

      // Safely convert the `fromChainId` to a number if within the range
      let fromChainIdInt;
      if (fromChainId <= Number.MAX_SAFE_INTEGER) {
        fromChainIdInt = Number(fromChainId);
        console.log("fromChainId safely converted:", fromChainIdInt);
      } else {
        throw new Error(
          "fromChainId is too large to convert safely to a Number."
        );
      }

      const response = await targetChainApiCall(
        transactionResponse.hash,
        fromAddress,
        toAddress,
        amount,
        parseInt(fromChainId),
        parseInt(toChainId),
        fromTokenSymbol,
        fromToken,
        toToken
      );

      // export async function targetChainApiCall(
      //   transactionHash,
      //   fromAddress,
      //   toAddress,
      //   amount,
      //   fromChainId,
      //   toChainId,
      //   fromTokenSymbol,
      //   fromContractAddress,
      //   toContractAddress
      // ) 

      // Notify the MPC with transaction details
      // const response1 = await notifyMPC(
      //   transactionResponse.hash,
      //   fromAddress,
      //   toAddress,
      //   amount,
      //   fromChain,
      //   fromChainIdInt,
      //   parseInt(toChainId),
      //   fromToken,
      //   toToken
      // );

      console.log("MPC service notified. API response received:", response);

      // Save the response and the MPC transaction hash
      setApiResponse(JSON.stringify(response, null, 2));
      setmpcResponceHash(response.tx);
      setStatus("success");
      setStatusMessage("Transfer Successful!");
    } catch (error) {
      console.error("Error during swap operation:", error);
      setStatus("error");
      setStatusMessage("Transfer failed. Please try again.");
      setRetry(true);
    } finally {
      setIsProcessing(false); // Reset processing state
      console.log("Swap process complete. Processing state reset.");
    }
  };

  useEffect(() => {
    if (defaultAccount) {
      setFromAddress(defaultAccount);
    }
  }, [defaultAccount]);

  const handleChainChange = (e, chainType) => {
    const chainId = e.target.value;
    const selectedChain = chains.find((chain) => chain.id === chainId);
    const tokenOptions = tokens[chainId] || [];

    if (chainType === "from") {
      setFromChainId(chainId);
      setFromChain(selectedChain.name);
      setFromToken(tokenOptions.length ? tokenOptions[0].address : "");
      setFromTokenSymbol(tokenOptions.length ? tokenOptions[0].symbol : "");
    } else {
      setToChainId(chainId);
      setToChain(selectedChain.name);
      setToToken(tokenOptions.length ? tokenOptions[0].address : "");
    }
  };

  return (
    <form onSubmit={handleSwap}>
      {" "}
      {/* Changed div to form */}
      <div className="flex flex-wrap gap-6">
        {/* From Chain, Token, and Address Fields */}
        <div className="flex-1 border p-6 rounded-lg shadow-sm bg-gray-100">
          <h3 className="text-xl font-bold mb-4 text-blue-600">Source Chain</h3>
          <SelectField
            label="Chain"
            id="from-chain"
            options={chains}
            value={fromChainId}
            onChange={(e) => handleChainChange(e, "from")}
            disabled={true} // Disable the dropdown
          />
          <SelectField
            label="Token"
            id="from-token"
            options={tokens[fromChainId] || []}
            value={fromTokenSymbol}
            onChange={(e) => setFromTokenSymbol(e.target.value)}
          />

          <FormField
            label="Address"
            id="from-address"
            value={fromAddress}
            onChange={(e) => setFromAddress(e.target.value)}
            placeholder="Enter source address"
          />
        </div>

        {/* To Chain, Token, and Address Fields */}
        <div className="flex-1 border p-6 rounded-lg shadow-sm bg-gray-100">
          <h3 className="text-xl font-bold mb-4 text-green-600">
            Target Chain
          </h3>
          <SelectField
            label="Chain"
            id="to-chain"
            options={chains}
            value={toChainId}
            onChange={(e) => handleChainChange(e, "to")}
          />
          <SelectField
            label="Token"
            id="to-token"
            options={tokens[toChainId] || []}
            value={toToken}
            onChange={(e) => setToToken(e.target.value)}
          />
          <FormField
            label="Address"
            id="to-address"
            value={toAddress}
            onChange={(e) => setToAddress(e.target.value)}
            placeholder="Enter destination address"
          />
        </div>
      </div>
      {/* Amount Field */}
      <div className="mt-6">
        <FormField
          label="Amount"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
        />
      </div>
      {/* Status and Buttons */}
      <StatusMessage
        statusMessage={statusMessage}
        transactionHash={transactionHash}
        mpcResponceHash={mpcResponceHash}
        fromChain={fromChain}
        toChain={toChain}
      />
      <div className="mt-6">
        <SwapButton isProcessing={isProcessing} status={status} retry={retry} />
      </div>
    </form>
  );
}

export default SwapForm;
