import axios from 'axios';

// Create an Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: 'http://13.251.179.90:4000/',  // Base URL for all requests
  headers: {
    'Content-Type': 'application/json',     // Set the content type for POST requests
  },
  timeout: 5000,                             // Set a request timeout
});

// Adding an interceptor to handle responses
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Handle error
    console.error('Error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
