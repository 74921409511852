import axios from "axios";
import { CONFIG, tokens } from "../utils/constants";
import axiosInstance from '../axiosConfig';


// Importing BigInt from 'big-integer' if needed; otherwise, you can use the native BigInt
const BigInt = require("big-integer");

// Constants from configuration
const apiKey = CONFIG.API_KEY;
const baseUrl = CONFIG.BASE_URL;
const mpcAddress = CONFIG.MPC_ADDRESS;

console.log("API Key:", apiKey);
console.log("Base URL:", baseUrl);
console.log("MPC Address:", mpcAddress);

// Define paths
const healthCheckPath = "/health/ready";

// Function to check server availability
export const checkServerAvailability = async () => {
  try {
    const url = `${baseUrl}${healthCheckPath}`;
    console.log("Connecting to checkServerAvailability API at...", url);
    const response = await axios.get(url);
    console.log("Server availability check response:", response.data);
    if (response.data.status === "ok") {
      console.log("Server availability check successful");
    }
    return response.data.status === "ok";
  } catch (error) {
    console.error("Server availability check failed:", error);
    return false;
  }
};

// Generate MPC Address
export const generateAddress = async (chainId) => {
  try {
    const url = `${baseUrl}/derive/account`;
    console.log("Connecting to generateAddress API at", url);

    // Convert BigInt to Number if it's within safe range
    let chainIdInt;
    if (chainId <= Number.MAX_SAFE_INTEGER) {
      chainIdInt = Number(chainId); // Convert BigInt to Number
      console.log("chainId converted to Number:", chainIdInt);
    } else {
      throw new Error("chainId is too large to convert safely to a Number.");
    }

    const requestBody = {
      hd_path: [44, 60, 0, 0, 0],
      chain_id: chainIdInt, // Send chain_id in correct uint format
    };

    console.log("Request body for generateAddress API:", requestBody);
    // const response = await axios.post("derive/account", requestBody, {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });

    const response = await axiosInstance.post('/drive/account', requestBody);
    console.log('Response:', response.data);

    console.log("Generated Address:", response.data);
    return response.data.address;
  } catch (error) {
    if (error.response) {
      console.error("Error response from MPC API:", error.response.data);
    } else {
      console.error("MPC API Error:", error.message);
    }
    throw error;
  }
};

export const makeSourseChainTransfer = async (
  transactionHash,
  fromAddress,
  toAddress,
  amount,
  fromChain,
  fromChainId,
  toChainId
) => {};

// notifyMPC(
//   transactionResponse.hash,
//   fromAddress,
//   toAddress,
//   amount,
//   fromChain,
//   fromChainIdInt,
//   parseInt(toChainId),
//   fromToken,
//   toToken
// );

// Function to handle token transfers based on the provided token and chain data
export async function targetChainApiCall(
  transactionHash,
  fromAddress,
  toAddress,
  amount,
  fromChainId,
  toChainId,
  fromTokenSymbol,
  fromContractAddress,
  toTokenSymbol
) {
  try {
    console.log("fromTokenSymbol ---- ", fromTokenSymbol);
    // Find the token information from the tokens object
    const tokenInfo = tokens[toChainId]?.find(
      (token) => token.symbol === toTokenSymbol
    );

    if (!tokenInfo) {
      throw new Error(
        `Token ${fromTokenSymbol} not found on chain ${fromChainId}`
      );
    }

    // Check if it's a native token or an ERC-20 token
    if (tokenInfo.address === null) {
      const url = `${baseUrl}/evm/send`;

      // Convert amount to string if it's a BigInt
      const amountStr = typeof amount === "bigint" ? amount.toString() : amount;

      console.log("Request body for notifyMPC API for native:", {
        fromAddress,
        toAddress,
        source_chain_id: fromChainId, // Use number for chain IDs
        target_chain_id: toChainId, // Use number for chain IDs
        amount: amountStr, // Stringify amount if it's a BigInt
        tx: transactionHash,
      });

      const response = await axios.post(
        "evm/send",
        {
          from: fromAddress,
          to: toAddress,
          source_chain_id: fromChainId, // Use number for chain IDs
          target_chain_id: toChainId, // Use number for chain IDs
          amount: amountStr, // Stringify amount if it's a BigInt
          tx: transactionHash,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`, // If your API requires an API key
          },
        }
      );

      console.log("MPC API Response:", response.data);
      return response.data;
    } else {
      const url = `${baseUrl}/evm/transfer`;

      // Convert amount to string if it's a BigInt
      const amountStr = typeof amount === "bigint" ? amount.toString() : amount;

      const toTokenInfo = tokens[toChainId]?.find(
        (token) => token.symbol === toTokenSymbol
      );
      const toContractAddress = toTokenInfo.address

      console.log("Request body for notifyMPC API for token erc 20:", {
        from: fromAddress,
        to: toAddress,
        source_chain_id: fromChainId, // Use number for chain IDs
        target_chain_id: toChainId, // Use number for chain IDs
        amount: amountStr, // Stringify amount if it's a BigInt
        tx: transactionHash,
        contract: toContractAddress,
      });

      const response = await axios.post(
        "evm/transfer",
        {
          from: fromAddress,
          to: toAddress,
          source_chain_id: fromChainId, // Use number for chain IDs
          target_chain_id: toChainId, // Use number for chain IDs
          amount: amountStr, // Stringify amount if it's a BigInt
          tx: transactionHash,
          contract: toContractAddress,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`, // If your API requires an API key
          },
        }
      );

      console.log("MPC API Response:", response.data);
      return response.data;
    }
  } catch (error) {
    console.error(`Error during token transfer:`, error);
    throw error;
  }
}

// Function to notify MPC
export const notifyMPC = async (
  transactionHash,
  fromAddress,
  toAddress,
  amount,
  fromChainId,
  toChainId
) => {
  // Console log to ensure all values are correct
  console.log("Notifying MPC with the following details:");
  console.log("Transaction Hash:", transactionHash);
  console.log("From Address:", fromAddress);
  console.log("To Address:", toAddress);
  console.log("Amount:", amount);
  console.log("From Chain ID:", fromChainId);
  console.log("To Chain ID:", toChainId);

  try {
    const url = `${baseUrl}/evm/send`;

    // Convert amount to string if it's a BigInt
    const amountStr = typeof amount === "bigint" ? amount.toString() : amount;

    console.log("Request body for notifyMPC API:", {
      fromAddress,
      toAddress,
      source_chain_id: fromChainId, // Use number for chain IDs
      target_chain_id: toChainId, // Use number for chain IDs
      amount: amountStr, // Stringify amount if it's a BigInt
      tx: transactionHash,
    });

    const response = await axios.post(
      "evm/send",
      {
        from: fromAddress,
        to: toAddress,
        source_chain_id: fromChainId, // Use number for chain IDs
        target_chain_id: toChainId, // Use number for chain IDs
        amount: amountStr, // Stringify amount if it's a BigInt
        tx: transactionHash,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`, // If your API requires an API key
        },
      }
    );

    console.log("MPC API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "MPC API Error:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
